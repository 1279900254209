import Link from 'next/link';
import { useRouter } from 'next/router';
import { useRef, useEffect, useState, MouseEventHandler, MouseEvent as ReactMouseEvent } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { useAnimation } from 'framer-motion';
import { Drawer, Overlay, Icons, Button, Divider } from '@studenten/ui-components';

import { APP_ROUTES } from 'constants/routes';
import { useOutsideClick } from 'features/layout/hooks/useOutsideClick';
import { isServer } from 'utils/helpers';

import {
  Container,
  Categories,
  SubCategories,
  Header,
  AuthButtonsContainer,
  BackButtonContainer,
  ListContainer,
  AdditionalLinksContainer,
} from './MobileMenu.styled';
import type { Props } from './types';

export const MobileMenu = ({
  isOpen,
  setOpen,
  isAuthenticated,
  onLogoutClick,
  menuItems,
  subItems,
}: Props) => {
  const router = useRouter();
  const [menuStep, setMenuStep] = useState<number>(1);
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const subCategoriesControls = useAnimation();

  useEffect(() => {
    subCategoriesControls.start({
      transition: { duration: 0.5 },
      x: menuStep === 1 ? 278 : 0,
    });
  }, [menuStep]);

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleClickMenuItem = (
    e: ReactMouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>,
    key?: string,
    href?: string
  ): void => {
    e.preventDefault();
    if (key) {
      setActiveKey(key);
      setMenuStep(2);
    } else if (href) {
      void router.push(href);
    } else {
      handleCloseMenu();
    }
  };

  const handleLogoutClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    onLogoutClick();
  };

  const menuCategories = menuItems?.map((menu) => (menu?.items ? menu.items : [])).flat();
  const submenu = subItems?.find(({ key }) => key === activeKey);

  useOutsideClick(drawerRef, handleCloseMenu);
  return (
    <RemoveScroll enabled={isOpen}>
      <Drawer isOpen={isOpen} ref={drawerRef}>
        <Container>
          <Categories>
            <Header>
              <Button variant="base" onClick={handleCloseMenu}>
                <Icons.Cross size="large" />
              </Button>
              <AuthButtonsContainer>
                {isServer() ? null : isAuthenticated ? (
                  <>
                    <Link href={APP_ROUTES.PROFILE} passHref prefetch={false}>
                      <Button variant="secondary" as="a">
                        Account
                      </Button>
                    </Link>
                    <Link href={APP_ROUTES.LOGOUT} passHref prefetch={false}>
                      <Button onClick={handleLogoutClick} variant="primary" as="a">
                        Logout
                      </Button>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link href={APP_ROUTES.LOGIN} passHref prefetch={false}>
                      <Button variant="secondary" as="a">
                        Inloggen
                      </Button>
                    </Link>
                    <Link href={APP_ROUTES.SIGN_UP} passHref prefetch={false}>
                      <Button variant="primary" as="a">
                        Inschrijven
                      </Button>
                    </Link>
                  </>
                )}
              </AuthButtonsContainer>
            </Header>
            <Divider />
            <ListContainer>
              <ul>
                {menuCategories.map(({ id, label, href, subMenuKey }) => (
                  <li key={id}>
                    {href ? (
                      <Link href={href || ''} prefetch={false}>
                        <a onClick={(e) => handleClickMenuItem(e, subMenuKey, href)}>
                          <span>{label}</span>
                          {subMenuKey && <Icons.RightArrow />}
                        </a>
                      </Link>
                    ) : (
                      <div onClick={(e) => handleClickMenuItem(e, subMenuKey, href)}>
                        <span>{label}</span>
                        {subMenuKey && <Icons.RightArrow />}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </ListContainer>
            <Divider />
            <AdditionalLinksContainer>
              <ul>
                <li>
                  <Link href={APP_ROUTES.SUBSCRIPTIONS} prefetch={false}>
                    <a>
                      <span>Subscriptions</span>
                    </a>
                  </Link>
                </li>
              </ul>
            </AdditionalLinksContainer>
          </Categories>
          <SubCategories animate={subCategoriesControls}>
            <Header>
              <BackButtonContainer>
                <Button variant="base" onClick={() => setMenuStep(1)}>
                  <Icons.LeftArrow />
                  Back
                </Button>
              </BackButtonContainer>
            </Header>
            <Divider />
            <ListContainer>
              <ul>
                {activeKey &&
                  submenu?.items?.map(({ id, label, href }) => (
                    <li key={id}>
                      <Link href={href} passHref prefetch={false}>
                        <a>
                          <span>{label}</span>
                        </a>
                      </Link>
                    </li>
                  ))}
              </ul>
            </ListContainer>
          </SubCategories>
        </Container>
      </Drawer>
      <Overlay isOpen={isOpen} />
    </RemoveScroll>
  );
};
