import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Button } from '@studenten/ui-components';

export const Container = styled.div`
  width: 278px;
  background-color: ${({ theme }) => theme.system.light};
  height: 100vh;
  overflow: hidden;
`;

export const Header = styled.div`
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

export const AuthButtonsContainer = styled.div`
  flex: 0 1 178px;
  display: flex;
  justify-content: space-between;
  a {
    font-size: 1.2rem;
    width: 81px;
    height: 38px;
  }
`;

export const BackButtonContainer = styled.div`
  button {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
  span {
    margin-right: 18px;
  }
`;

export const Categories = styled(motion.div)`
  z-index: 1;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background-color: inherit;
  & > ${Header} {
    svg {
      color: ${({ theme }) => theme.brand.darkBlue};
    }
  }
`;

export const SubCategories = styled(motion.div)`
  transform: translateX(278px);
  /* transition: transform 250ms cubic-bezier(0, 0, 0.38, 0.9); */
  height: 100%;
  z-index: 2;
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  background-color: inherit;
`;

export const ListContainer = styled.div`
  margin: 12px 0;
  overflow-y: scroll;
  max-height: 100%;
  ul > li {
    color: ${({ theme }) => theme.system.darkGreyActive};
    & > a,
    div {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 16px;
      padding-right: 30px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > span:first-child {
        margin-right: 9px;
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      & > span:nth-child(2) {
        height: 8px;
        width: 8px;
      }
    }
  }
`;

export const AdditionalLinksContainer = styled.div`
  margin-top: 12px;
  ul > li {
    color: ${({ theme }) => theme.system.black};
    & > a {
      display: inline-block;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 16px;
      padding-right: 30px;
      width: 100%;
      & > span {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
`;

export const AuthProfileButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  & > div:first-child {
    margin-right: 10px;
  }
`;

export const AuthProfileContainer = styled(Button)`
  display: flex;
  padding: 0;
`;

export const ProfileInitials = styled.span`
  display: inline-flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.system.white};
  background-color: ${({ theme }) => theme.brand.blue};
  font-size: 1.2rem;
  line-height: 140%;
  margin-right: 8px;
`;

export const ProfileNameAndSoname = styled.span`
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.system.black};
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.5rem;
`;

export const FavoriteIconWrapper = styled.div`
  svg {
    fill: none;
    stroke: ${({ theme }) => theme.system.darkGreyActive};
  }
`;
